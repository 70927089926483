var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',[_c('RDataTable',{attrs:{"items":_vm.tableData,"fields":_vm.columns,"table-props":{ loading: _vm.loading }},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":_vm.getLocation(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('RWidgetStatus',{attrs:{"status":item.status}})],1)]}},{key:"start_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.start_date ? _vm.formatDate(item.start_date, 'MMM dd, yyyy') : '')+" ")])]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.end_date ? _vm.formatDate(item.end_date, 'MMM dd, yyyy') : '')+" ")])]}},{key:"reactivate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"info","shape":"pill"},on:{"click":function($event){return _vm.reactivateProperty(item)}}},[_vm._v(" Reactivate ")])],1)]}}])})],1)],1),_c('PropertyReactivationModal',{attrs:{"show":_vm.showReactivationModal,"property":_vm.propertyToReactivate},on:{"close":function($event){_vm.showReactivationModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }