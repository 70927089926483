<template>
  <div>
    <CRow>
      <CCol>
        <RDataTable
          :items="tableData"
          :fields="columns"
          :table-props="{ loading }"
        >
          <template #name="{ item }">
            <td>
              <CLink :to="getLocation(item)">
                {{ item.name }}
              </CLink>
            </td>
          </template>
          <template #status="{ item }">
            <td>
              <RWidgetStatus :status="item.status" />
            </td>
          </template>
          <template #start_date="{ item }">
            <td>
              {{ item.start_date ? formatDate(item.start_date, 'MMM dd, yyyy') : '' }}
            </td>
          </template>
          <template #end_date="{ item }">
            <td>
              {{ item.end_date ? formatDate(item.end_date, 'MMM dd, yyyy') : '' }}
            </td>
          </template>
          <template #reactivate="{ item }">
            <td>
              <CButton
                color="info"
                shape="pill"
                @click="reactivateProperty(item)"
              >
                Reactivate
              </CButton>
            </td>
          </template>
        </RDataTable>
      </CCol>
    </CRow>
    <PropertyReactivationModal
      :show="showReactivationModal"
      :property="propertyToReactivate"
      @close="showReactivationModal = false"
    />
  </div>
</template>

<script>
import { RDataTable } from '@/components/tables'
import { RWidgetStatus } from '@/components/widgets'
import { RooofAPI } from '@/services/api/resources'
import { formatDate } from '@/utils'

import PropertyReactivationModal from '../_components/PropertyReactivationModal'

const columns = [
  {
    key: 'name',
    label: 'Property Name',
    _style: 'width: 20%'
  },
  {
    key: 'street',
    label: 'Address',
    _style: 'width: 20%'
  },
  {
    key: 'city',
    label: 'City',
    _style: 'width: 10%'
  },
  {
    key: 'state',
    label: 'State',
    _style: 'width: 5%'
  },
  {
    key: 'start_date',
    label: 'Start Date',
    _style: 'width: 13%'
  },
  {
    key: 'end_date',
    label: 'End Date',
    _style: 'width: 13%'
  },
  {
    key: 'status',
    label: 'Status',
    _style: 'width: 15%'
  },
  {
    key: 'reactivate',
    label: '',
    _style: 'width: 4%'
  }
]

export default {
  name: 'InactivePropertyTable',
  components: {
    RDataTable,
    RWidgetStatus,
    PropertyReactivationModal
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      columns,
      showReactivationModal: false,
      propertyToReactivate: null
    }
  },
  computed: {
    tableData () {
      // Map address values directly to row obj, allows automatic sort/filter
      return this.data.map(property => {
        property.street = property.address.street
        property.city = property.address.city
        property.state = property.address.state
        return property
      }).sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    }
  },
  created () {
    this.formatDate = formatDate
  },
  methods: {
    /**
     * Generate the route location object for navigating to
     * the property reporting overview
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getLocation (row) {
      return {
        name: 'Reporting',
        params: {
          cid: this.$route.params.cid,
          pid: row.id
        }
      }
    },
    /**
     * Fetches the full Rooof property and opens the reactivation modal.
     *
     * @param {Object} property - Rooof property summary
     */
    async reactivateProperty (property) {
      const response = await RooofAPI.properties.retrieve({ id: property.id })
      if (response) {
        this.propertyToReactivate = response.data
        this.showReactivationModal = true
      }
    }
  }
}
</script>
