<template>
  <div>
    <CRow>
      <CCol>
        <h2>
          Inactive Properties
          <span class="h4">({{ filteredProperties.length }})</span>
        </h2>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RTableFilterOptions :config="filterConfig" @change="handleFilterChange" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <InactivePropertyTable :data="filteredProperties" :loading="loading" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { RTableFilterOptions } from '@/components/tables'
import { RooofAPI } from '@/services/api/resources'
import constants from '@/utils/constants'

import InactivePropertyTable from './InactivePropertyTable'

export default {
  name: 'InactivePropertyList',
  /**
   * React to param changes to refresh the property data.
   */
  beforeRouteUpdate (to, from, next) {
    if (to.params.cid !== from.params.cid) {
      this.fetchPropertyList(to.params.cid)
    }
    next()
  },
  components: {
    InactivePropertyTable,
    RTableFilterOptions
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      properties: [],
      loading: false,
      filters: {
        state: [],
        propertyName: ''
      }
    }
  },
  computed: {
    filterOptions () {
      const states = new Set()

      for (const prop of this.properties) {
        if (prop.address.state !== null) {
          states.add(prop.address.state)
        }
      }
      return {
        state: Array.from(states).sort().map(state => ({
          label: state || '(none)',
          value: state
        }))
      }
    },
    filterConfig () {
      return {
        state: {
          type: 'multiselect',
          label: 'State',
          options: this.filterOptions.state
        },
        propertyName: {
          type: 'input',
          label: 'Property Name'
        }
      }
    },
    filteredProperties () {
      return this.properties.filter(prop => {
        let include = true
        if (this.filters.state.length > 0) {
          include &= this.filters.state.includes(prop.address.state)
        }
        if (this.filters.propertyName !== '') {
          include &= prop.name.toLowerCase().includes(this.filters.propertyName.toLowerCase())
        }
        return include
      })
    }
  },
  created () {
    this.fetchPropertyList(this.$route.params.cid)
  },
  methods: {
    /**
     * Fetch the list of inactive properties for this company.
     *
     * @param {Number} id
     */
    async fetchPropertyList (id) {
      this.loading = true
      const params = new URLSearchParams({ status: constants.rooof.Status.INACTIVE })
      const response = await RooofAPI.companies.propertySummary({ id, params })
      if (response) {
        this.properties = response.data
      }
      this.loading = false
    },
    /**
     * Handler for filter change event.
     *
     * @param {Object} filters - updated filter values
     */
    handleFilterChange (filters) {
      this.filters = filters
    }
  }
}
</script>
